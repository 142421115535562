import React, {Component} from 'react';

import config from '../../../../config';
import navigation from '../../../../menu-items';


class Breadcrumb extends Component {
    state = {
        main: [],
        item: []
    };

    componentDidMount() {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item, index);
            }
            return false;
        });
    };

    componentWillReceiveProps = () => {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item);
            }
            return false;
        });
    };

    getCollapse = (item) => {
        if (item.children) {
            (item.children).filter( collapse => {
                if (collapse.type && collapse.type === 'collapse') {
                    this.getCollapse(collapse,);
                } else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === config.basename+collapse.url) {
                        this.setState({item: collapse, main: item});
                    }
                }
                return false;
            });
        }
    };

    render() {
        if (this.state.main && this.state.main.type === 'collapse') {
        }

        if (this.state.item && this.state.item.type === 'item') {

            if(this.state.item.breadcrumbs !== false) {
            }

        }

        document.title = 'Mivex Shop';

        return (
            <div>
            </div>
        );
    }
}

export default Breadcrumb;