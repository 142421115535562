import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";

import LoginPage from '../Demo/Authentication/SignIn/LoginPage'

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    
    render() {
        routes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>

                        <Route exact path="/" render={() => (
                            <Redirect to="/authentication/login"/>
                        )}/>

                        <Switch>

                            <Route path="/authentication/login">
                                <LoginPage></LoginPage>
                            </Route>

                            <Route path="/posiljke/online-prodaja" component={AdminLayout}></Route>
                            <Route path="/posiljke/online-prodaja-obrada" component={AdminLayout}></Route>
                            <Route path="/posiljke/online-prodaja-zavrsene" component={AdminLayout}></Route>
                            <Route path="/posiljke/online-prodaja-provjera" component={AdminLayout}></Route>
                            <Route path="/posiljke/online-prodaja-kontrolne" component={AdminLayout}></Route>
                            <Route path="/posiljke/online-prodaja-promjerene" component={AdminLayout}></Route>
                            <Route path="/posiljke/online-prodaja-obavijestiti" component={AdminLayout}></Route>
                            <Route path="/posiljke/online-prodaja-sve-posiljke" component={AdminLayout}></Route>
                            <Route path="/posiljke/export-posta" component={AdminLayout}></Route>
                            <Route path="/posiljke/stakla-mapiranje" component={AdminLayout}></Route>

                            <Route path="/kontrolne-posiljke/student" component={AdminLayout}></Route>

                            <Route path="/posiljke/radnja-obrada" component={AdminLayout}></Route>
                            <Route path="/posiljke/radnja-provjeriti" component={AdminLayout}></Route>
                            <Route path="/posiljke/radnja-zavrsene" component={AdminLayout}></Route>
                            <Route path="/posiljke/racuni-rucna-izmjena" component={AdminLayout}></Route>

                            <Route path="/posiljke/administracija-obrada" component={AdminLayout}></Route>
                            <Route path="/posiljke/administracija-provjeriti" component={AdminLayout}></Route>
                            <Route path="/posiljke/administracija-zavrsene" component={AdminLayout}></Route>
                            <Route path="/posiljke/administracija-pomjerene" component={AdminLayout}></Route>
                            <Route path="/prenos-robe" component={AdminLayout}></Route>

                            <Route path="/posiljke/pakovanje-posiljke" component={AdminLayout}></Route>
                            <Route path="/posiljke/zapakovane-posiljke" component={AdminLayout}></Route>

                            <Route path="/tables/profili" component={AdminLayout}></Route>
                            <Route path="/posiljke/suma-otkupnina" component={AdminLayout}></Route>
                            <Route path="/posiljke/export-kasa" component={AdminLayout}></Route>
                            <Route path="/posiljke/export-prenosnica" component={AdminLayout}></Route>
                            <Route path="/posiljke/prenosne-posiljke" component={AdminLayout}></Route>
                            <Route path="/posiljke/zakljuci-dan" component={AdminLayout}></Route>
                            <Route path="/posiljke/taxi" component={AdminLayout}></Route>

                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
